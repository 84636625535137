<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/mycourse/courseList?ccid=' + ccid }">课程签到</el-breadcrumb-item>
      <el-breadcrumb-item>签到活动</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="operate-container">
      <i class="el-icon-reading"></i>
      <span style="margin-left:10px;">我的课程</span>
      <div style="font-size:30px;font-weight:bold;" class="course-title">
        {{ courseName }}
      </div>
    </el-card>
    <!-- 表格数据 -->
    <el-card class="righttable">
      <i class="el-icon-tickets"></i>
      <span>签到活动列表</span>
      <el-tooltip content="选择签到活动导出整体签到情况" placement="top" style="float: right">
        <el-button type="warning" size="mini" @click="opendia()">导出签到情况</el-button>
      </el-tooltip>
      <el-table :data="signData" border stripe style="width: 100%; height: auto"
        :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="序号" type="index" min-width="100" align="center"></el-table-column>
        <!-- <el-table-column prop="title" label="签到名称" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.title }}</p>
          </template>
</el-table-column> -->
        <el-table-column prop="caption" label="签到标题" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.title }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="acttype" label="签到名单" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.className }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="acttype" label="签到说明" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.caption }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="signStopType" label="签到方式" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.signStopType == 1 ? '手动控制签到' : '倒计时签到' }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" sortable label="创建时间" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.createTime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="300" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleShow(scope.$index, scope.row)">查看签到环节</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper" :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize" :page-sizes="[5, 10, 15]" :total="total"></el-pagination>
      </div>
    </el-card>

    <!-- 第一个对话框 -->
    <el-dialog title="选择签到活动导出" :visible.sync="firstdialogVisible" width="50%" top="5vh">
      <el-card class="dialog-righttable">
        <i class="el-icon-document-checked"></i>
        <span> 选择要导出的字段</span>
        <el-checkbox-group style="margin-top:8px" v-model="checkedValue" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="(index, name) in attributeName" :label="name" :key="index">{{ index }}</el-checkbox>
        </el-checkbox-group>
      </el-card>
      <el-card class="dialog-righttable">
        <i class="el-icon-tickets"></i>
        <span>签到活动列表</span>
        <el-tooltip content="选择签到活动导出整体签到情况" placement="top" style="float: right">
          <el-button type="warning" size="mini" :disabled="actIdArray && actIdArray.length > 0 ? false : true"
            @click="dialogTableVisible = true">确认选中</el-button>
        </el-tooltip>
        <el-table :data="signData2" border stripe @selection-change="handleSelectionChange" height="300px"
          style="width: 100%; height: auto;" :default-sort="{ prop: 'date', order: 'descending' }">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="title" label="签到名称" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.title }}</p>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="firstdialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!--选择名单导出结果对话框-->
    <el-dialog title="选择名单" :visible.sync="dialogTableVisible" width="50%">
      <a @click="downloadxls()" style="color: #419eff; border-bottom: 0.5px dotted #419eff">下载模板</a>
      <div style="text-align: center">
        <el-upload class="upload-demo" drag action="https://xiangqian.club:8001/list/excel" :on-change="handleChange"
          :on-success="handleSuccess" :headers="headers" :data="listDataforexcel" name="list" ref="upload"
          accept=".xls,.xlsx">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将xls/xlsx文件拖到此处，或<em>点击上传</em>
          </div>
        </el-upload>
      </div>
      <div>
        <el-table :data="listHistory" height="300" style="margin-top: 0 !important">
          <el-table-column prop="name" label="签到名单"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button size="mini" @click="download(scope.row)">导出</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const defaultFormInline = {
  pageNum: 1,
  pageSize: 5,
  university: null,
  academy: null,
  name: null
}
export default {
  name: 'signList',
  data() {
    return {
      signlist: [],
      signData: [],
      formInline: {
        university: '',
        academy: '',
        name: '',
        pageNum: 1,
        pageSize: 5
      },
      tid: null,
      uid: null,
      courseName: '', //课程名字
      ccid: '', //课程的id
      // 分页
      total: 0,
      flag: null,
      firstdialogVisible: false,
      //选中的签到活动id数组
      actIdArray: [],
      //对话框中的表格数据
      signData2: [],
      //上传文件对话框
      dialogTableVisible: false,
      //上传文件的header
      headers: {
        Authorization: 'Bearar ' + sessionStorage.getItem('Authorization')
      },
      listDataforexcel: {
        listName: '',
        uid: window.sessionStorage.getItem('uid')
      },
      //所有的名单
      listHistory: [],
      //导出的数据
      checkedValue: [0, 1, 2, 3, 4, 5],
      attributeName: ['已签到', '未签到', '未认证', '迟到', '请假', '其他']
    }
  },
  created() {
    this.tid = window.sessionStorage.getItem('uid')
    this.uid = window.sessionStorage.getItem('uid')
    this.courseName = window.sessionStorage.getItem('title')
    this.ccid = window.sessionStorage.getItem('ccid')
    window.sessionStorage.setItem('tid', this.tid)

    this.selectList()
  },
  mounted: function () { },
  methods: {
    //处理导出excel选择的字段
    handleCheckedCitiesChange(value) {
      console.log(this.checkedValue)
    },
    //下载模板
    downloadxls() {
      window.location.href = process.env.BASE_URL + '名单模板.xls'
    },
    // 获取上传的文件
    async handleChange(file, fileList) {
      var filenamesplit = file.name.split('.')
      this.listDataforexcel.listName = filenamesplit[0]
      this.getHistoryList()
    },
    //上传成功
    handleSuccess(e) {
      this.getHistoryList()
      if (e.code == 200) {
        this.$message({
          type: 'success',
          message: e.message
        })
      } else {
        this.$message({
          type: 'error',
          message: e.message
        })
      }
    },
    // 获取历史名单
    async getHistoryList() {
      const { data: res } = await this.$http.get('/list/get2', {
        params: {
          uid: this.uid
        }
      })
      this.listHistory = res.data.list
      console.log(this.listHistory)
    },
    //导出数据
    async download(data1) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http({
        url: `/rec/exportSignInRecord`,
        method: 'post',
        data: {
          actIdList: this.actIdArray,
          titleList: this.checkedValue,
          signInClassId: data1.id
        },
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob' // 表明返回服务器返回的数据类型
      }).then(res => {
        // 处理返回的文件流
        const content = res.data
        const blob = new Blob([content])
        const fileName = '签到结果.xlsx'
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
          loading.close()
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
          loading.close()
        }
      })
      this.dialogTableVisible = false
      this.firstdialogVisible = false
    },
    //获取对话框中的表格数据
    async selectList2() {
      const { data: res } = await this.$http.get('/cou/findActByccid', {
        params: {
          ccid: this.ccid,
          pageNum: 1,
          pageSize: 1000
        }
      })
      if (res != null) {
        this.signData2 = res.data
      }
    },
    //批量选中数据
    handleSelectionChange(val) {
      console.log('val', val)
      this.actIdArray = []
      for (var i = 0; i < val.length; i++) {
        this.$set(this.actIdArray, i, val[i].actId)
      }
      console.log('现在选中的数据', this.actIdArray)
    },
    //打开导出签到活动对话框
    opendia() {
      this.selectList2()
      this.getHistoryList()
      this.actIdArray = []
      this.checkedValue = [0, 1, 2, 3, 4, 5]
      this.firstdialogVisible = true
    },
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val

      // 请求对应的分页数据
      this.selectList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.selectList()
    },

    // 重置搜索表单
    handleResetSearch() {
      this.formInline = Object.assign({}, defaultFormInline)
    },
    // 查询签到环节
    handleShow(index, row) {
      // window.sessionStorage.setItem('caption', row.caption)
      // window.sessionStorage.setItem('title', row.title)
      window.sessionStorage.setItem('cid', row.cid)
      // window.sessionStorage.setItem('acttype', row.actType)
      // window.sessionStorage.setItem('createtime', row.createTime)
      // window.sessionStorage.setItem('className', row.className)
      window.sessionStorage.setItem('actId', row.actId)
      window.sessionStorage.setItem('signStopType', row.signStopType)
      this.$router.push({
        // path: '/signList/signlinkList'
        path: '/mycourse/signlinkList/signlinkList'
      })
    },
    onSubmit() { },

    // 查询签到活动
    async selectList() {
      const { data: res } = await this.$http.get('/cou/findActByccid', {
        params: {
          ccid: this.ccid,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        this.signData = res.data
        this.total = res.total
      }
    }
  }
}
</script>

<style scoped>
/* body {
  padding-right: 0px !important;
  overflow: hidden;
} */
.app-container {
  width: 100%;
  /* height: 100vh;
  padding-bottom: 300px;
  overflow: auto !important; */
}

.input-width {
  width: 203px;
}

.home-breadcrumb {
  position: relative;
  top: -52px;
  left: 29px;
}

.operate-container .course-title {
  text-align: center;
}
</style>
